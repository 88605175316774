// @TODO:
// - improve fullscreen experience (hide UI while animating?)
// - fix widescreen/fullscreen embed options
// - catch api calls, return fallback data, e.g. empty arrays/objects/etc where meaningful, halt or retry calls where necessary
// - review all promise reject/catch implementations (e.g. remove rejected items from `shaperizedPointIdsRef`, and so on)
// @LATERDO:
// - user distance in SiteCard/Site (probably store location in a cookie from within MapLocation.js)
// - review reactions and replace them with `computed`s where meaningful
// - review array observables (points/shapes/trips/etc.) and their `slice/replace` implementations
// - cache points
// - implement `supercluster`
// - thinner trails/areas on lower zooms?
// - @media prefers reduced motion

import React, { useEffect, useRef } from "react"
import { reaction } from "mobx"
import { observer } from "mobx-react-lite"
import { createGlobalStyle } from "styled-components"
import { em } from "polished"
import { debounce, uniqBy } from "lodash"
import turfBbox from "@turf/bbox"
import { featureCollection as turfFeatureCollection } from "@turf/helpers"
import "./assets/css/reset.css"

import Base from "./components/Base"
// import ErrorState from "./components/ErrorState"

import { useStore } from "./store"
import theme from "./theme"
import embedParams from "./config/embedParams"
import useAppWindow from "./utils/useAppWindow"
import useAppTraffic from "./utils/useAppTraffic"
import { postEmbedMessage } from "./utils/embedMessage"
import { trackPageview, enableAutoOutboundLinkTrack } from "./utils/track"
import { fetchTypesensePoints } from "./utils/typesense"
import { queryToTypesenseParams, queryDifference } from "./utils/query"
import {
  fetchApiCategories,
  fetchApiGuides,
  fetchApiMunicipalities,
  fetchApiCounties,
  fetchApiSiteShapes,
  fetchApiUser,
  fetchApiTrip,
  fetchApiList,
  // fetchApiSearchBoundary,
} from "./utils/api"

const App = () => {
  const store = useStore()

  useAppWindow(store)
  useAppTraffic(store)

  const initialDataLoadedRef = useRef(-1)
  const queryChangedRef = useRef(true)
  const lockPointFetchRef = useRef(false)
  // const totalPointsByQueryRef = useRef(``)
  const trackMapBoundsForPointsRef = useRef(false)
  const trackMapBoundsForShapesRef = useRef(false)
  const pointsFetchingRef = useRef(false)
  const pointsFetchQueuedRef = useRef(false)
  const fetchShapesOnMapLoad = useRef(false)
  const shaperizedPointIdsRef = useRef([])

  // const [status, statusRef, setStatus] = useReferredState(`success`) // loading|success|error

  const initData = () => {
    fetchPoints().catch(() => null)
    fetchApiCategories().then((c) => store.setCategories(c))
    fetchApiGuides().then((g) => store.setGuides(g))
    fetchApiCounties().then((c) => store.setCounties(c))
    fetchApiMunicipalities().then((m) => store.setMunicipalities(m))
  }

  const checkInitialDataLoaded = () => {
    ++initialDataLoadedRef.current
    if (initialDataLoadedRef.current !== 1) return

    store.setInitiallyLoaded()

    if (
      embedParams.preselectedSiteId &&
      store.findPoint(embedParams.preselectedSiteId)
    )
      store.setPointPreselectedId(embedParams.preselectedSiteId)

    const finalize = () => {
      fetchShapes() // map initially loaded and zoomed in, do fetch shapes for unclustered points
      store.map.on(`move`, trackMapBoundsForFeatures)
      store.setMapInteractive(true)
      if (embedParams.scrollZoom === false) store.map.scrollZoom.disable()
    }

    if (
      embedParams.autoBounds &&
      !embedParams.bounds &&
      !embedParams.center &&
      (store.shapes.length || store.points.length)
    ) {
      const fitBoundsParams = {}
      if (embedParams.zoom) fitBoundsParams.zoom = embedParams.zoom
      else fitBoundsParams.maxZoom = 15

      const fitBounds = turfBbox(
        turfFeatureCollection(
          embedParams.preselectedSiteFit
            ? [store.pointPreselected, store.shapePreselected].filter(Boolean)
            : [...store.shapes, ...store.points]
        )
      )

      store
        .travelMap({
          instant: false,
          fitBounds,
          fitBoundsParams,
        })
        .then(finalize)
    } else finalize()
  }

  const fetchPoints = (isSecondRound = false) => {
    if (pointsFetchingRef.current) {
      pointsFetchQueuedRef.current = true
      return new Promise((resolve, reject) => reject(new Error()))
    }

    pointsFetchingRef.current = true
    pointsFetchQueuedRef.current = false

    // console.log(
    //   `fetchPoints`,
    //   isSecondRound ? `(2nd round)` : ``,
    //   queryChangedRef.current ? `(query changed)` : ``
    // )

    let usersPromise = []
    let tripsPromise = []
    let listsPromise = []

    if (queryChangedRef.current) {
      store.query.forEach(
        (q) => q.type == `organization` && store.getOrganization(q.value)
      )

      usersPromise = Promise.all(
        store.query
          .filter((q) => q.type == `user` && !store.findUser(q.value))
          .map((q) => fetchApiUser({ id: q.value }))
      )

      tripsPromise = Promise.all(
        store.query
          .filter((q) => q.type == `trip` && !store.findTrip(q.value))
          .map((q) => fetchApiTrip({ id: q.value }))
      )

      listsPromise = Promise.all(
        store.query
          .filter((q) => q.type == `list` && !store.findList(q.value))
          .map((q) => fetchApiList({ id: q.value }))
      )
    }

    return Promise.all([usersPromise, tripsPromise, listsPromise]).then(
      ([users, trips, lists]) => {
        store.addToUsers(users.filter(Boolean))
        store.addToTrips(trips.filter(Boolean))
        store.addToLists(lists.filter(Boolean))

        return queryToTypesenseParams(store)
          .then((typesenseParams) => {
            store.setQueryFailed(false)

            let typesenseBounds = []
            if (
              (queryChangedRef.current || trackMapBoundsForPointsRef.current) &&
              store.map
            ) {
              // @LATERDO: (expand bounds virtually?), save value, do not fetch if current bbox fully falls in the saved value
              const mapBounds = store.map.getBounds()
              typesenseBounds = [
                mapBounds._ne.lng,
                mapBounds._ne.lat,
                mapBounds._sw.lng,
                mapBounds._sw.lat,
              ]
            }

            const typesensePromise = fetchTypesensePoints({
              query: typesenseParams.query,
              filter: typesenseParams.filters,
              bounds: typesenseBounds,
            })

            // if (lockPointFetchRef.current) {
            //   @LATERDO: call Typesense counter instead of fetching features as they won't be used
            // }

            typesensePromise.then(({ points, isComplete, total }) => {
              pointsFetchingRef.current = false
              // totalPointsByQueryRef.current = total
              const typesenseLimit = 2500
              const lockPointFetch = points.length >= 2500
              // if (lockPointFetchRef.current && lockPointFetch) return

              lockPointFetchRef.current = lockPointFetch
              trackMapBoundsForPointsRef.current = false
              trackMapBoundsForShapesRef.current = false
              const needsSecondRound = !isComplete && total < typesenseLimit
              let shouldFetchShapes = false

              // console.log(
              //   `typesensePromise`,
              //   `points`,
              //   points.length,
              //   `isComplete`,
              //   isComplete,
              //   `total`,
              //   total,
              //   `lockPointFetch`,
              //   lockPointFetch
              // )

              //  query's too broad, we'll need to track map move for points
              if (!needsSecondRound && !isComplete) {
                trackMapBoundsForPointsRef.current = true
                shouldFetchShapes = true
              }

              // query has changed, let's toggle shape visibility
              if ((queryChangedRef.current || isSecondRound) && store.map) {
                store.shapes.forEach((s) =>
                  store.map.setFeatureState(
                    { id: s.id, source: `geojson-shapes` },
                    {
                      invisible: !points.find(
                        (p) => p.id == s.properties.parent
                      ),
                    }
                  )
                )
              }

              // absolutely all query matching points were fetched, let's see what we can do with shapes
              if (isComplete) {
                const shapelessPoints = points.filter(
                  (p) =>
                    [`trail`, `area`].includes(p.properties.type) &&
                    !shaperizedPointIdsRef.current.includes(p.id)
                )

                if (shapelessPoints.length <= 100) {
                  // there's a small amount of shapes that we don't have, let's fetch them
                  fetchAndAddShapesForPoints(shapelessPoints)
                } else {
                  // unfortunatelly there's a lot of points that have shapes, we'll fetch them based on map bounds...
                  trackMapBoundsForShapesRef.current = true
                  shouldFetchShapes = true
                }
              }

              queryChangedRef.current = false
              store.setPoints(points)

              if (shouldFetchShapes && !lockPointFetch && !needsSecondRound) {
                if (store.map) {
                  if (isComplete) fetchShapes()
                  else window.setTimeout(fetchShapes, 500)
                } else fetchShapesOnMapLoad.current = true
              } else if (lockPointFetch) checkInitialDataLoaded()

              if (needsSecondRound) {
                trackMapBoundsForPointsRef.current = false
                fetchPoints(true).catch(() => null)
              } else if (pointsFetchQueuedRef.current)
                fetchPoints().catch(() => null)
            })

            return typesensePromise
          })
          .catch(() => {
            pointsFetchingRef.current = false
            checkInitialDataLoaded()
            store.setQueryFailed(true)
          })
      }
    )
  }

  const fetchShapes = async () => {
    // @LATERDO: don't fetch if all point matching shapes have been downloaded

    let renderedPoints = uniqBy(
      store.map.getLayer(`points`)
        ? store.map.queryRenderedFeatures({
            layers: [`points`],
            filter: [`all`, [`in`, `type`, `trail`, `area`]],
          })
        : [],
      `id`
    ).filter((p) => !shaperizedPointIdsRef.current.includes(p.id))

    if (renderedPoints.length > 100) {
      renderedPoints = renderedPoints.filter(
        (p) => p.properties.importance == 3
      )

      if (renderedPoints.length <= 100) {
        fetchAndAddShapesForPoints(renderedPoints)

        // console.log(
        //   `fetchShapes`,
        //   `renderedPoints`,
        //   `important`,
        //   renderedPoints.length
        // )
      } else checkInitialDataLoaded()
    } else {
      fetchAndAddShapesForPoints(renderedPoints)

      const renderedClusters = store.map.getLayer(`points`)
        ? store.map.queryRenderedFeatures({
            layers: [`points-cluster`],
          })
        : []

      if (renderedClusters.length) {
        const clusterSource = store.map.getSource(`geojson-points`)
        const clusteredPoints = []

        renderedClusters.forEach((cluster, i) =>
          // `getClusterLeaves` works asynchronously
          clusterSource.getClusterLeaves(
            cluster.properties.cluster_id,
            cluster.properties.point_count,
            0,
            (err, points) => {
              if (points) {
                clusteredPoints.push(
                  ...points.filter(
                    (p) =>
                      p.properties.importance == 3 &&
                      [`trail`, `area`].includes(p.properties.type) &&
                      !shaperizedPointIdsRef.current.includes(p.id)
                  )
                )
              }

              if (renderedClusters.length - 1 == i) {
                // if (clusteredPoints.length > 100)
                //   clusteredPoints = clusteredPoints.filter(
                //     (p) => p.properties.importance == 3
                //   )

                if (clusteredPoints.length <= 100) {
                  fetchAndAddShapesForPoints(clusteredPoints)
                  // console.log(
                  //   `fetchShapes`,
                  //   `clusteredPoints`,
                  //   clusteredPoints.length
                  // )
                }
              }
            }
          )
        )
      }
    }

    // let bounds = []
    // if (store.map) {
    //   const mapBounds = store.map.getBounds()
    //   bounds = [
    //     mapBounds._sw.lng,
    //     mapBounds._sw.lat,
    //     mapBounds._ne.lng,
    //     mapBounds._ne.lat,
    //   ]
    // } else if (features.length)
    //   bounds = turfBbox(turfFeatureCollection(features))

    // fetchApiSearchBoundary({
    //   typesense: {
    //     query: typesenseParams.query,
    //     filters: `${typesenseParams.filters} AND (type:Trail OR type:Area)`,
    //   },
    //   boundary: bounds,
    // }).then((items) => console.log(items))
  }

  const trackMapBoundsForFeatures = debounce(() => {
    if (store.mapTraveling) return

    if (trackMapBoundsForPointsRef.current) fetchPoints().catch(() => null)
    else if (trackMapBoundsForShapesRef.current) fetchShapes()
  }, 500)

  const fetchAndAddShapesForPoints = (points) => {
    Promise.all(
      points.map((p) => {
        shaperizedPointIdsRef.current.push(p.id)
        return fetchApiSiteShapes({ id: p.id })
      })
    )
      .then((shapesAll) => {
        store.addShapes(shapesAll.filter(Boolean).flat())
        checkInitialDataLoaded()
      })
      .catch(() => null)
  }

  useEffect(() => {
    trackPageview()

    if (!embedParams.bounds && !embedParams.center) initData()
    postEmbedMessage(`loaded`)

    const reactionMap = reaction(
      () => store.map,
      () => {
        store.setMapInteractive(false)

        checkInitialDataLoaded()
        if (embedParams.bounds || embedParams.center) initData()

        if (fetchShapesOnMapLoad.current) {
          fetchShapesOnMapLoad.current = false
          window.setTimeout(fetchShapes, 1000)
        }
      }
    )

    const reactionQuery = reaction(
      () => store.query,
      (currentQuery, previousQuery) => {
        postEmbedMessage(`setQuery`, currentQuery)

        queryChangedRef.current = true

        const fitBounds =
          currentQuery.length &&
          !!queryDifference(currentQuery, previousQuery).find((q) =>
            [
              `site`,
              `site_with_neighbours`,
              `guide`,
              `county`,
              `municipality`,
              `organization`,
            ].includes(q.type)
          )

        fetchPoints()
          .then(
            () =>
              fitBounds &&
              window.setTimeout(
                () =>
                  store.points.length &&
                  store.travelMap({
                    instant: false,
                    fitBounds: turfBbox(turfFeatureCollection(store.points)),
                  }),
                500
              )
          )
          .catch(() => null)
      },
      { delay: 100 }
    )

    const reactionPointActiveId = reaction(
      () => store.pointActiveId,
      () => {
        postEmbedMessage(`setMapPointActive`, store.pointActiveId)
      }
    )

    const reactionPointHoverId = reaction(
      () => store.pointHoverId,
      () => {
        postEmbedMessage(`setMapPointHovered`, store.pointHoverId)
      }
    )

    const reactionFullscreen = reaction(
      () => store.fullscreen,
      () => {
        window.setTimeout(() => {
          if (
            store.fullscreen &&
            embedParams.menu == `fullscreen` &&
            window.matchMedia(theme.mq.mobileUp).matches
          )
            store.setMenu(true)
        }, 400)

        if (embedParams.scrollZoom === false) {
          if (store.fullscreen) store.map.scrollZoom.enable()
          else store.map.scrollZoom.disable()
        }
      }
    )

    const disableAutoOutboundLinkTrack = enableAutoOutboundLinkTrack()

    return () => {
      reactionMap()
      reactionQuery()
      reactionPointActiveId()
      reactionPointHoverId()
      reactionFullscreen()
      disableAutoOutboundLinkTrack()
    }
  }, [])

  return (
    <>
      <GlobalStyles />

      <Base />

      {/* {status === `error` && (
          <>
            <HideLoadingIndicator />
            <ErrorState />
          </>
        )} */}
    </>
  )
}

export default observer(App)

const GlobalStyles = createGlobalStyle`
  * {
    line-height: calc(2px + 2.3ex + 2px); /* https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/ */
    text-decoration-thickness: 1px;
    text-underline-offset: ${em(4)};
  }

  *:focus:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.seaGreen};
    outline-offset: 2px;
  }

  html,
  body {
    width: 100%;
    overflow-x: clip;
  }

  html {
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)}

    min-height: 100%;
    font-size: ${embedParams.client == `stf` ? `110%` : `100%`};  /* a11y */
    color: ${({ theme }) => theme.colors.gunmetal};
    background-color: ${({ theme }) => theme.colors.aliceBlue};
    background-image: url("/images/logo-animated.svg");
    background-size: 3.75rem 3.75rem;
    background-position: center center;
    background-repeat: no-repeat;
  }

  strong {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }

  @media print {
    @page {
      size: A4 landscape;
    }
  }
`

// const HideLoadingIndicator = createGlobalStyle`
//   html {
//     background-image: none;
//   }
// `
